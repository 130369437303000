import React from "react"
import styled from "styled-components"
import RichText from "../RichText"
import ImageField from "./ImageField"
import BlockContent from "@sanity/block-content-to-react"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import Img from "gatsby-image"

const serializers = {
  marks: {
    internalLink: props => {
      console.log(props)
      return (
        <a
          href={
            props.mark.reference &&
            props.mark.reference.file &&
            props.mark.reference.file.asset &&
            props.mark.reference.file.asset.url
          }
        >
          {props.children}
        </a>
      )
    },
  },
  types: {
    image: props => {
      const sanityConfig = { projectId: "59662g5w", dataset: "production" }
      const imageAssetId =
        props.node && props.node.asset && props.node.asset._ref

      const fluidProps = getFluidGatsbyImage(
        imageAssetId,
        { maxWidth: 1024 },
        sanityConfig
      )

      return <Img fluid={fluidProps} />
    },
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    html: props => {
      return <div dangerouslySetInnerHTML={{ __html: props.node.html }}></div>
    },
  },
}

const Host = styled.div``

const StreamField = ({ blocks, ...props }) => {
  blocks = blocks || []
  console.log(blocks)
  return (
    <Host {...props}>
      <BlockContent
        blocks={blocks}
        serializers={serializers}
        projectId="59662g5w"
        dataset="production"
      />
    </Host>
  )
}

export default StreamField
