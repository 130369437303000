import React from "react"
import styled from "styled-components"

const Host = styled.div``

const RichText = ({ value, ...props }) => {
  return <Host {...props} dangerouslySetInnerHTML={{ __html: value }}></Host>
}

export default RichText
