import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"

import styled from "styled-components"
import MultiLineText from "../components/MultiLineText"
import RichText from "../components/RichText"
import StreamField from "../components/blocks/StreamField"
const BlockContent = require("@sanity/block-content-to-react")

const TitleRow = styled.div`
  padding-bottom: 0.2rem;
  border-bottom: 1px solid var(--grey);
`

const Title = styled.h1`
  margin: 0;
  flex: 1;
`
const Date = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  margin-right: 0.5rem;
`

const Body = styled(StreamField)`
  margin-top: 1rem;
  max-width: 40rem;
`

export default ({ data }) => {
  const { page } = data

  return (
    <>
      <SEO title={page.title} />
      <TitleRow>
        <Title>{page.title}</Title>
      </TitleRow>

      <Body blocks={page.body} />
    </>
  )
}

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      body: _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`
